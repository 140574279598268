import { GatsbyImage, getImage, getSrc, getSrcSet } from "gatsby-plugin-image"
import React, { useState } from "react"

import Icons from '../../components/Icons'
import Lightbox from 'react-image-lightbox'
import Slider from "../../components/Slider"
import { getLargestSrc } from "../../utils/utils"

const ProductImages = ({images, title}) => {

  const hasImages = images.length > 0;
  const hasMultipleImages = images.length > 1;

  const [lightbox_indx, setLightboxIndx] = useState(0);
  const [lightbox_open, setLightboxOpen] = useState(false);

  if (hasImages) {
    if (hasMultipleImages) {
      return <>
            <div className="relative">
              <Slider className="w-full" limitAspect="4/5" forcePause={lightbox_open}>
                {images.map((x, indx) => {
                  if (x) {
                    return <a key={`product-image-${x.id}`} href={getSrc(x)} className="block border-none no-underline" onClick={(e) =>{
                      e.preventDefault();
                      setLightboxIndx(indx);
                      setLightboxOpen(true);
                    }}>
                      <GatsbyImage loading={indx === 0 ? "eager" : "lazy"} image={getImage(x.gatsbyImageData)} alt={x.altText ? x.altText : `Product Image of ${title} #${indx + 1}`} className="block pixelate" />
                    </a>;
                  }
                  
                  return null;
                })}
              </Slider>

              <Icons type="search_plus_solid" className="absolute right-4 top-4 block w-4 text-white opacity-80" />
            </div>
            
            {lightbox_open ? 
              <Lightbox
                mainSrc={getLargestSrc(getSrcSet(images[lightbox_indx]?.gatsbyImageData))}
                nextSrc={getLargestSrc(getSrcSet(images[(lightbox_indx + 1) % images.length]?.gatsbyImageData))}
                prevSrc={getLargestSrc(getSrcSet(images[(lightbox_indx + images.length - 1) % images.length]?.gatsbyImageData))}
                onCloseRequest={() => setLightboxOpen(false)}
                onMovePrevRequest={() =>
                  setLightboxIndx((lightbox_indx + images.length - 1) % images.length)
                }
                onMoveNextRequest={() =>
                  setLightboxIndx((lightbox_indx + 1) % images.length)
                }
              />
              : null 
            }
          </>;
    } else {
      return <div>
          {images.map((image, index) => (
            <GatsbyImage
              key={`product-image-${image.id}`}
              objectFit="contain"
              loading={index === 0 ? "eager" : "lazy"}
              alt={image.altText ? image.altText : `Product Image of ${title} #${index + 1}`}
              image={getImage(image.gatsbyImageData)}
            /> 
          ))}
      </div>
    }
  }

  // No images
  return <div className="p-5 pb-0 lg:p-0 lg:pt-2 lg:pl-5 ">
    <div className="rounded-lg aspect-w-4 aspect-h-5 bg-black border border-gray-475">
      <div className="text-gray-450 h-full w-full flex items-center justify-center">No image</div>
    </div>
  </div>;
}


export default ProductImages