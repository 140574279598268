import * as React from "react"

const NumericInput = ({
  onIncrement,
  onDecrement,
  className,
  disabled,
  uid,
  ...props
  
}) => {
  return (
    <div className={`flex  items-center ${disabled ? 'opacity-50' : ''}`}>
       <button
        id={`decrement-${uid}`}
        aria-label={`Decrement`}
        aria-controls={`number-input-${uid}`}
        role="spinbutton"
        disabled={disabled}
        className="text-white text-xl  p-4 w-12 h-12 border border-gray-475 border-r-0 bg-black flex justify-center items-center hover:bg-gray-900  rounded-l-md"
        onClick={onDecrement}
      >
        <span>-</span>
      </button>

      <input
        id={`number-input-${uid}`}
        aria-label="Quantity"
        disabled={disabled}
        type="text"
        inputMode="numeric"
        pattern="[0-9]*"
        className=" text-white text-lg m-0 rounded-none max-w-14 h-12  text-center leading-3 p-3 border-t border-b  border-gray-475 bg-black  relative z-10 "
        {...props}
      />

      <button
        id={`increment-${uid}`}
        aria-label={`Increment`}
        aria-controls={`number-input-${uid}`}
        role="spinbutton"
        disabled={disabled}
        className="text-white text-xl  p-4 w-12 h-12 border border-gray-475 border-l-0 bg-black flex justify-center items-center hover:bg-gray-900 rounded-r-md"
        onClick={onIncrement}
      >
        <span>+</span>
      </button>
     
    </div>
  )
};

export default NumericInput
