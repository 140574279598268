import * as React from "react"

import { graphql, withPrefix } from "gatsby"

import AddToCart from "../../../components/store/AddToCart"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"
import Helmet from "react-helmet"
import Layout from "../../../components/Layout"
import NumericInput from "../../../components/store/NumericInput"
import ProductCondition from "../../../components/store/ProductCondition"
import ProductImages from "../../../components/store/ProductImages"
import Seo from "../../../components/seo"
import { StoreContext } from "../../../context/store-context"
import StoreFooter from "../../../components/StoreFooter"
import { formatPrice } from "../../../utils/format-price"
import { getTagCondition } from "../../../utils/utils"
import isEqual from "lodash.isequal"

export default function Product({pageContext, data: { product, suggestions } }) {
  const {
    options,
    variants,
    variants: [initialVariant],
    priceRangeV2,
    title,
    descriptionHtml,
    images,
    // images: [firstImage],
    tags
  } = product
  const { client } = React.useContext(StoreContext);
  const {
    breadcrumb: { crumbs },
  } = pageContext;

  const [variant, setVariant] = React.useState({ ...initialVariant })
  const [quantity, setQuantity] = React.useState(1)
  const productVariant = client.product.helpers.variantForOptions(product, variant) || variant;
  const [available, setAvailable] = React.useState(productVariant.availableForSale);
  const [price, setPrice] = React.useState(formatPrice(
    priceRangeV2.minVariantPrice.currencyCode,
    variant.price
  ));
  const [comparePrice, setComparePrice] = React.useState(null);

  const checkAvailablity = React.useCallback(
    (productId) => {
      client.product.fetch(productId).then((fetchedProduct) => {
        const result =
          fetchedProduct?.variants.filter(
            (variant) => variant.id === productVariant.shopifyId
          ) ?? []

        if (result.length > 0) {
          setAvailable(result[0].available);

          setPrice(formatPrice(
            result[0].priceV2.currencyCode,
            result[0].priceV2.amount
          ));

          if (result[0].compareAtPriceV2) {
            setComparePrice(formatPrice(
              result[0].compareAtPriceV2.currencyCode,
              result[0].compareAtPriceV2.amount
            ));
          }
        }
      })
    },
    [productVariant.shopifyId, client.product]
  )

  const handleOptionChange = (index, event) => {
    const value = event.target.value

    if (value === "") {
      return
    }

    const currentOptions = [...variant.selectedOptions]

    currentOptions[index] = {
      ...currentOptions[index],
      value,
    }

    const selectedVariant = variants.find((variant) => {
      return isEqual(currentOptions, variant.selectedOptions)
    })

    setVariant({ ...selectedVariant })
  }


  const handleQuantityChange = (value) => {
    let val = Number(value);

    if (!val || val < 1) {
      val = 1;
    }

    if (val > 99) {
      val = 99;
    }

    setQuantity(val);
  }

  function doIncrement() {
    handleQuantityChange(Number(quantity || 0) + 1)
  }

  function doDecrement() {
    handleQuantityChange(Number(quantity || 0) - 1)
  }


  React.useEffect(() => {
    checkAvailablity(product.shopifyId)
  }, [productVariant.shopifyId, checkAvailablity, product.shopifyId])

  const hasVariants = variants.length > 1;

  const sorted_imgs = React.useMemo(
    () => {
      return images.sort((a, b) => {
        let a_name = a?.src?.split('/').pop().split('?').shift();
        let b_name = b?.src?.split('/').pop().split('?').shift();
    
        return a_name - b_name;
      });
    },
    [images]
  );


  const MIN = 50;
  const MAX = 3000;
  
  const shopify_meta = JSON.stringify({
    variants: variants.map((x) => {
      let x_price = parseFloat(x.price);

      // Note, use the latest available price. Kind of hacky.
      if (x.id === variant.id) {
        x_price = parseFloat(price.replace('$', '').replace(',', ''));
      }

      return {
        id: x.legacyResourceId,
        price: formatPrice(
          priceRangeV2.minVariantPrice.currencyCode,
          x_price / 4
        ),
        eligible: x_price >= MIN && x_price <= MAX
      };
    }),
    min_price: `$${MIN}`,
    max_price: `$${MAX}`
  });


  return (
    <Layout >
      <Seo
          title={`${title} | Store`}
       
          // image={getSrc(firstImage.gatsbyImageData)}
        />
        <Helmet>
          <script src={withPrefix('payment-terms-ui.js')} />
        </Helmet>
      <div className="store-product-page bg-dark-night">
        <div className="max-w-1920 mx-auto">
          <div className="flex flex-col lg:flex-row flex-wrap lg:pb-10">
            <div className="w-full lg:w-1/2"> 
              <ProductImages images={sorted_imgs} title={title} />
            </div>

            <div className="lg:order-first w-full"><Breadcrumb crumbs={crumbs} crumbLabel={title} crumbSeparator="»" hiddenCrumbs={['/store/products']} /></div>
            <div className="w-full lg:w-1/2 p-5 lg:p-12">
              
              <h1 className="text-3xl font-bold mb-8">{title}</h1>
              

              <ProductCondition condition={getTagCondition(tags)} />

              <div className="py-4">
                {comparePrice ? <div className="text-sm line-through text-gray-400">{comparePrice}</div> : null}
                <h2 className=" text-green-500 font-bold text-2xl">
                  <span>{price}</span>
                </h2>
              </div>

              {/* NOTE: price as key, a way to force remount */}
              {product.productType !== 'Gift Cards' ?
                <div key={price} className="mb-6 text-sm lg:max-w-md text-gray-400 p-3 bg-gray-800 rounded-md">
                  <shopify-payment-terms variant-id={variant.legacyResourceId} shopify-meta={shopify_meta} />
                </div>
                : null
              }

              <fieldset className="">
                {hasVariants &&
                  options.map(({ id, name, values }, index) => (
                    <div className="">
                      <select
                        aria-label="Variants"
                        className="text-white py-3 px-6 rounded-md border  border-gray-475 bg-black mb-6 w-full lg:w-auto lg:min-w-1/2"
                        onChange={(event) => handleOptionChange(index, event)}
                      >
                        <option value="">{`Select ${name}`}</option>
                        {values.map((value) => (
                          <option value={value} key={`${name}-${value}`}>
                            {value}
                          </option>
                        ))}
                      </select>
                    </div>
                  ))}
              </fieldset>
              <div className="flex flex-col lg:items-start">
                {productVariant.inventoryQuantity > 1 ?
                  <NumericInput
                    uid={product.id}
                    aria-label="Quantity"
                    onIncrement={doIncrement}
                    onDecrement={doDecrement}
                    onChange={(e) => handleQuantityChange(e.currentTarget.value)}
                    value={quantity}
                  />
                  :
                  <div className="text-sm italic">{productVariant.inventoryQuantity === 1 ? 'Only 1 available' : ''  }</div>
                }
                <AddToCart
                  variantId={productVariant.shopifyId}
                  quantity={quantity}
                  available={available}
                />
              </div>

              <div className="mt-8 pt-8 border-t border-gray-600 ele-style" dangerouslySetInnerHTML={{ __html: descriptionHtml}} />

              
              {/* <div className="">
                <span className="">Type</span>
                <span className="">
                  <Link to={product.productTypeSlug}>{product.productType}</Link>
                </span>
                <span className="">Tags</span>
                <span className="">
                  {product.tags.map((tag) => (
                    <div>{tag}</div>
                  ))}
                </span>
              </div> */}
            </div>
          </div>

          <div className="px-5 pb-5 lg:px-12 lg:pb-8"><StoreFooter /></div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($id: String!) {
    product: shopifyProduct(id: { eq: $id }) {
      id
      shopifyId
      title
      descriptionHtml
      productType
      tags
      priceRangeV2 {
        maxVariantPrice {
          amount
          currencyCode
        }
        minVariantPrice {
          amount
          currencyCode
        }
      }
      images {
        altText
        id
        src
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, breakpoints: [400,800], sizes: "(max-width: 500px) 200px, (min-width: 1024px) 50vw, 100vw")
      }
      variants {
        shopifyId
        availableForSale
        inventoryQuantity
        legacyResourceId
        title
        price
        selectedOptions {
          name
          value
        }
      }
      options {
        name
        values
        id
      }
    }
    suggestions: allShopifyProduct(
      limit: 3
      filter: { id: { ne: $id } }
    ) {
      nodes {
        ...ProductCard
      }
    }
  }
`
