import * as React from "react"

import { Link } from "gatsby"
import { StoreContext } from "../../context/store-context"

const AddToCart = ({ variantId, quantity, available, ...props }) => {
  const { addVariantToCart, adding, loading, didJustAddToCart, checkout } = React.useContext(StoreContext)

  function addToCart(e) {
    e.preventDefault()
    addVariantToCart(variantId, quantity)
  }

  let btn_txt = available ? "Add to cart" : "Out of stock";

  if (adding) {
    btn_txt = 'Adding...';
  } else if (didJustAddToCart) {
    btn_txt = 'Added!';
  }

  return <>
    <button
      type="submit"
      className={`mt-6 btn w-full md:w-auto text-xl ${available ? '' : 'btn-disabled'}`}
      onClick={addToCart}
      disabled={!available || loading}
      {...props}
    >
      {btn_txt}
    </button>
  
    {checkout.lineItems.length ? <span className="mt-6 block text-center"><Link to="/store/cart">View cart</Link></span>: null}
  </>
};

export default AddToCart